import React from 'react';
import axios from 'axios';

import AuthContext from '../../contexts/AuthContext';
import Loader from '../Loader';

interface Props {
  children: React.ReactElement;
}

interface User {}

interface State {
  authVerified: boolean;
  user: User | null;
  authToken: string | null;
}

class Auth extends React.Component<Props, State> {
  state = {
    authVerified: false,
    user: null,
    authToken: null,
  };

  componentDidMount() {
    this._verifyUser();
  }

  private _verifyUser = () => {
    // TODO: Verify User
    try {
      const authToken = window.localStorage.getItem('AUTH_TOKEN');
      const userData = window.localStorage.getItem('USER');
      const user = userData ? JSON.parse(userData) : null;
      if (authToken && user) {
        this.setState({
          authToken,
          user,
        });
      }
    } catch (error) {
      this.setState({
        authToken: null,
        user: null,
      });
    } finally {
      this.setState({ authVerified: true });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  signInWithEmail = async (email: string, password: string) => {
    const api = axios.create({ baseURL: process.env.REACT_APP_API_BASE_URL });
    const {
      data: { jwt, account },
    } = await api.post<{ jwt: string; account: User }>('/login', {
      username: email,
      password,
    });
    this.setState({ authToken: jwt, user: account }, () => {
      window.localStorage.setItem('AUTH_TOKEN', jwt);
      window.localStorage.setItem('USER', JSON.stringify(account));
    });
  };

  signOut = () => {
    this.setState({ user: null, authToken: null }, () => {
      window.localStorage.removeItem('AUTH_TOKEN');
      window.localStorage.removeItem('USER');
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  initiateResetPassword = (email: string) => {};

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  resetPassword = (email: string, newPassword: string) => {};

  render() {
    const { children } = this.props;

    const { authToken, user, authVerified } = this.state;

    const {
      signInWithEmail,
      signOut,
      initiateResetPassword,
      resetPassword,
    } = this;

    if (!authVerified) {
      return <Loader text="Verifying User" />;
    }

    return (
      <AuthContext.Provider
        value={{
          authToken,
          user,
          signInWithEmail,
          signOut,
          initiateResetPassword,
          resetPassword,
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  }
}

export default Auth;
