import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import Helmet from 'react-helmet';

import AppShell from '../../components/AppShell';
import UnauthorizedRequests from './components/UnauthorizedRequests';
import RequestPurposeChart from './components/RequestPurposeChart';
import RequestDurationChart from './components/RequestDurationChart';

const Dashboard: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <AppShell title="Analytics">
        <Container>
          <Row gutter={16}>
            <StyledCol span={12}>
              <RequestPurposeChart />
            </StyledCol>
            <StyledCol span={12}>
              <RequestDurationChart />
            </StyledCol>
            <StyledCol span={24}>
              <UnauthorizedRequests />
            </StyledCol>
          </Row>
        </Container>
      </AppShell>
    </>
  );
};

export default Dashboard;

const Container = styled.div`
  padding: 16px;
`;

const StyledCol = styled(Col)`
  margin: 8px 0;
`;
