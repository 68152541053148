import React, { useState } from 'react';
import { withTheme } from 'styled-components';
import { Table, Typography } from 'antd';
import moment from 'moment';
import { orderBy } from 'lodash-es';

import usePoll from '../../../hooks/usePoll';
import { Theme } from '../../../styles/theme';

const PAGE_ITEMS_COUNT = 10;

interface UnauthorizedRequest {
  _id: string;
  sender: number;
  comments: string;
  reason: string;
  createdAt: number;
}

interface Props {
  theme: Theme;
}

const UnauthorizedRequests: React.FC<Props> = ({ theme }) => {
  const [pageIndex, setPageIndex] = useState(1);

  const { data: unauthorizedRequests, loading } = usePoll<{
    data: Array<UnauthorizedRequest>;
    stats: { totalItems: number };
  }>(
    `/otp/history/unauthorised?page=${pageIndex}&limit=${PAGE_ITEMS_COUNT}&sort=createdAt&order=desc`,
  );

  return (
    <>
      <Typography.Paragraph strong>Unauthorized Requests</Typography.Paragraph>
      <Table
        bordered
        loading={loading}
        pagination={{
          current: pageIndex,
          onChange: pageNumber => {
            setPageIndex(pageNumber);
          },
          pageSize: PAGE_ITEMS_COUNT,
          total: unauthorizedRequests
            ? unauthorizedRequests.stats.totalItems
            : undefined,
        }}
        dataSource={
          unauthorizedRequests
            ? orderBy(
                unauthorizedRequests.data,
                request => request.createdAt,
                'desc',
              )
            : []
        }
        rowKey="_id"
        bodyStyle={{
          backgroundColor: theme.lightBackgroundColor,
        }}
        columns={[
          {
            dataIndex: 'sNo',
            title: 'S.No',
            render: (text, render, index) => index + 1,
          },
          {
            dataIndex: 'createdAt',
            title: 'Date',
            render: (text, record) =>
              moment(record.createdAt).format('DD MMM YYYY HH:mm a'),
          },
          {
            dataIndex: 'sender',
            title: 'Sender',
            render: (text: string) => text.substr(2),
          },
          {
            dataIndex: 'comments',
            title: 'SMS',
          },
          {
            dataIndex: 'reason',
            title: 'Reason',
          },
        ]}
      />
    </>
  );
};

export default withTheme(UnauthorizedRequests);
