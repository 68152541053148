import { useEffect, useState } from 'react';
import Axios, { CancelTokenSource } from 'axios';

import useApi from './useApi';

const usePoll = <P extends object>(
  apiEndpoint: string,
  interval: number = 60000,
) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<P>();
  const [error, setError] = useState<string>();

  const api = useApi();

  useEffect(() => {
    // const source = Axios.CancelToken.source();
    let source: null | CancelTokenSource = null;

    const fetchData = async (showLoader: boolean = false) => {
      if (showLoader) {
        setLoading(true);
      }
      try {
        if (source) {
          source.cancel();
        }
        source = Axios.CancelToken.source();
        const { data: responseData } = await api.get(apiEndpoint, {
          cancelToken: source.token,
        });
        setData(responseData);
        if (showLoader) {
          setLoading(false);
        }
      } catch (e) {
        if (!Axios.isCancel(e)) {
          // TODO: Get human readable message from response
          setError('Something went wrong');
          if (showLoader) {
            setLoading(false);
          }
        }
      }
    };

    const intervalId = window.setInterval(fetchData, interval);

    fetchData(true);

    return () => {
      if (source) {
        source.cancel();
      }
      if (intervalId) {
        window.clearInterval(intervalId);
      }
    };
  }, [api, apiEndpoint, interval]);

  return {
    loading,
    data,
    error,
    setData,
  };
};

export default usePoll;
