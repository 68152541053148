import React, { cloneElement, useState, useCallback } from 'react';
import { Form, Drawer, DatePicker, Button, Select, message } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { orderBy, get } from 'lodash-es';
import moment from 'moment';
import qs from 'querystring';

import AsyncSelect from '../../../components/AsyncSelect';
import useApi from '../../../hooks/useApi';
import { Station } from '../../../types';

interface Props {
  trigger: JSX.Element;
  form: WrappedFormUtils;
}

const DownloadData: React.FC<Props> = ({
  trigger,
  form: { getFieldDecorator, resetFields, validateFields },
}) => {
  const api = useApi();

  const [loading, setLoading] = useState(false);

  const [drawerOpened, setDrawerOpened] = useState(false);

  const handleDrawerOpen = useCallback(() => {
    setDrawerOpened(true);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setDrawerOpened(false);
    resetFields();
  }, [resetFields]);

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      validateFields(async (fieldErrors, values) => {
        if (!fieldErrors) {
          const { period, stations, duration, purpose } = values as {
            period: [moment.Moment, moment.Moment];
            stations: Array<string>;
            duration: Array<string>;
            purpose: Array<string>;
          };
          setLoading(true);
          try {
            const {
              data: { downloadLink },
            } = await api.post<{ downloadLink: string }>(
              `/report?${qs.stringify({
                ...(period
                  ? {
                      from: period[0].startOf('day').valueOf(),
                      to: period[1].endOf('day').valueOf(),
                    }
                  : undefined),
                ...(stations ? { stations: stations.join(',') } : undefined),
                ...(duration ? { duration: duration.join(',') } : undefined),
                ...(purpose ? { purpose: purpose.join(',') } : undefined),
              })}`,
            );
            window.open(downloadLink, '_blank');
          } catch (error) {
            const errorMessage = get(
              error,
              'response.data.message',
              'Something went wrong. Please try again',
            );
            message.error(errorMessage);
          } finally {
            setLoading(false);
          }
        }
      });
    },
    [api, validateFields],
  );

  const fetchStationsData = useCallback(async () => {
    const { data } = await api.get<Array<Station>>('/stations');
    return orderBy(data, option => option.code).map(option => ({
      value: option._id,
      label: option.code,
    }));
  }, [api]);

  return (
    <>
      {cloneElement(trigger, { onClick: handleDrawerOpen })}
      <Drawer
        visible={drawerOpened}
        onClose={handleDrawerClose}
        title="Download Report"
        width={320}
      >
        <Form onSubmit={handleSubmit}>
          <Form.Item label="Start Date - End Date">
            {getFieldDecorator('period')(
              <DatePicker.RangePicker placeholder={['From', 'To']} />,
            )}
          </Form.Item>
          <Form.Item label="Stations">
            {getFieldDecorator('stations')(
              <AsyncSelect
                placeholder="Station"
                mode="multiple"
                fetchData={fetchStationsData}
              />,
            )}
          </Form.Item>
          <Form.Item label="Duration of Request">
            {getFieldDecorator('duration')(
              <Select placeholder="Duration" mode="multiple">
                {[
                  {
                    text: '0-5 minutes',
                    value: '0-5',
                  },
                  {
                    text: '6-10 minutes',
                    value: '6-10',
                  },
                  {
                    text: '11-15 minutes',
                    value: '11-15',
                  },
                  {
                    text: '16-20 minutes',
                    value: '16-20',
                  },
                  {
                    text: '21-25 minutes',
                    value: '21-25',
                  },
                  {
                    text: '26-30 minutes',
                    value: '26-30',
                  },
                  {
                    text: 'more than 30 minutes',
                    value: '31-Infinity',
                  },
                ].map(option => (
                  <Select.Option key={option.value}>
                    {option.text}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
          <Form.Item label="Purpose">
            {getFieldDecorator('purpose')(
              <Select mode="multiple" placeholder="Purpose">
                {[
                  {
                    text:
                      'For attending failures of S&T gears from within relay rooms.',
                    value: '1',
                  },
                  {
                    text: 'For periodical maintenance of Relay Rooms.',
                    value: '2',
                  },
                  {
                    text: 'For special activities and works in RR',
                    value: '3',
                  },
                  { text: 'Emergency cases.', value: '4' },
                ].map(option => (
                  <Select.Option key={option.value}>
                    {option.text}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form>
      </Drawer>
    </>
  );
};

export default Form.create<Props>({ name: 'downloadData' })(DownloadData);
