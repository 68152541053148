import React, { useState, useCallback } from 'react';
import {
  Form,
  Input,
  Select,
  Table,
  Button,
  Row,
  Col,
  Divider,
  message,
} from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import validator from 'validator';
import { get } from 'lodash-es';

import useApi from '../../../hooks/useApi';
import { Section } from '../../../types';

const uuid = () =>
  Math.random()
    .toString(36)
    .substring(7);

interface Props {
  form: WrappedFormUtils;
  onCreate?: (sectionCreated: Section) => void;
}

const CreateSectionForm: React.FC<Props> = ({
  form: { getFieldDecorator, validateFields },
  onCreate,
}) => {
  const api = useApi();

  const [loading, setLoading] = useState(false);

  const [sntUsers, setSntUsers] = useState<Array<string>>([
    uuid(),
    uuid(),
    uuid(),
  ]);

  const handleSntUserAdd = useCallback(() => {
    setSntUsers(sntUsersState => [...sntUsersState, uuid()]);
  }, []);

  const handleSntUserDelete = useCallback(userId => {
    setSntUsers(sntUsersState => sntUsersState.filter(id => id !== userId));
  }, []);

  const [trafficUsers, setTrafficUsers] = useState<Array<string>>([uuid()]);

  const handleTrafficUserAdd = useCallback(() => {
    setTrafficUsers(trafficUsersState => [...trafficUsersState, uuid()]);
  }, []);

  const handleTrafficUserDelete = useCallback(userId => {
    setTrafficUsers(trafficUsersState =>
      trafficUsersState.filter(id => id !== userId),
    );
  }, []);

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      validateFields(async (fieldErrors, values) => {
        if (!fieldErrors) {
          const data = {
            name: values.name,
            stations: (values.stations as Array<string>).map(station => ({
              code: station,
            })),
            sntUsers: sntUsers.map(userId => ({
              name: values[`sntUser-${userId}`].name,
              designation: values[`sntUser-${userId}`].designation,
              mobile: `91${values[`sntUser-${userId}`].mobile}`,
            })),
            trafficUsers: trafficUsers.map(userId => ({
              name: values[`trafficUser-${userId}`].name,
              designation: values[`trafficUser-${userId}`].designation,
              mobile: `91${values[`trafficUser-${userId}`].mobile}`,
            })),
          };

          setLoading(true);
          try {
            const { data: sectionCreated } = await api.post<Section>(
              '/sections',
              data,
            );
            if (onCreate) {
              onCreate(sectionCreated);
            }
          } catch (error) {
            const errorMessage = get(error, 'response.data.message');
            message.error(errorMessage);
            setLoading(false);
          }
        }
      });
    },
    [api, onCreate, sntUsers, trafficUsers, validateFields],
  );

  return (
    <Form className="h-full flex flex-col" onSubmit={handleSubmit}>
      <div className="pb-8 flex-1 overflow-auto">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Section Name">
              {getFieldDecorator('name', {
                rules: [
                  { required: true, message: 'Section name is required' },
                ],
              })(<Input />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Stations">
              {getFieldDecorator('stations', {
                rules: [
                  {
                    required: true,
                    message: 'Stations are required',
                    type: 'array',
                  },
                ],
              })(<Select mode="tags" dropdownStyle={{ display: 'none' }} />)}
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <div className="text-sm font-medium text-heading-color mb-2">
          SnT Users
        </div>
        <Table
          pagination={false}
          bordered
          className="mb-2"
          dataSource={sntUsers.map(id => ({ id }))}
          rowKey="id"
          columns={[
            {
              dataIndex: 'name',
              title: 'Name',
              render: (data, record) => (
                <Form.Item>
                  {getFieldDecorator(`sntUser-${record.id}.name`)(
                    <Input placeholder="User Name" />,
                  )}
                </Form.Item>
              ),
            },
            {
              dataIndex: 'designation',
              title: 'Designation',
              render: (data, record) => (
                <Form.Item>
                  {getFieldDecorator(`sntUser-${record.id}.designation`, {
                    rules: [
                      { required: true, message: 'Designation is required' },
                    ],
                  })(<Input placeholder="User Designation" />)}
                </Form.Item>
              ),
            },
            {
              dataIndex: 'mobile',
              title: 'Mobile',
              render: (data, record) => (
                <Form.Item>
                  {getFieldDecorator(`sntUser-${record.id}.mobile`, {
                    rules: [
                      { required: true, message: 'Mobile is required' },
                      {
                        type: 'mobile',
                        message: 'Mobile number is not valid',
                        validator: async (rule, value) => {
                          if (!validator.isMobilePhone(value, 'en-IN')) {
                            throw new Error('Mobile number is not valid');
                          }
                        },
                      },
                    ],
                  })(<Input placeholder="Mobile" />)}
                </Form.Item>
              ),
            },
            {
              dataIndex: 'action',
              render: (data, record) => (
                <Button
                  className="opacity-25 hover:opacity-100"
                  icon="delete"
                  onClick={() => {
                    handleSntUserDelete(record.id);
                  }}
                />
              ),
            },
          ]}
        />
        <Button htmlType="button" icon="user-add" onClick={handleSntUserAdd}>
          Add User
        </Button>

        <Divider />

        <div className="text-sm font-medium text-heading-color mb-2">
          Traffic Users
        </div>
        <Table
          pagination={false}
          bordered
          className="mb-2"
          dataSource={trafficUsers.map(id => ({ id }))}
          rowKey="id"
          columns={[
            {
              dataIndex: 'name',
              title: 'Name',
              render: (data, record) => (
                <Form.Item>
                  {getFieldDecorator(`trafficUser-${record.id}.name`)(
                    <Input placeholder="User Name" />,
                  )}
                </Form.Item>
              ),
            },
            {
              dataIndex: 'designation',
              title: 'Designation',
              render: (data, record) => (
                <Form.Item>
                  {getFieldDecorator(`trafficUser-${record.id}.designation`, {
                    rules: [
                      { required: true, message: 'Designation is required' },
                    ],
                  })(<Input placeholder="User Designation" />)}
                </Form.Item>
              ),
            },
            {
              dataIndex: 'mobile',
              title: 'Mobile',
              render: (data, record) => (
                <Form.Item>
                  {getFieldDecorator(`trafficUser-${record.id}.mobile`, {
                    rules: [
                      { required: true, message: 'Mobile is required' },
                      {
                        type: 'mobile',
                        message: 'Mobile number is not valid',
                        validator: async (rule, value) => {
                          if (!validator.isMobilePhone(value, 'en-IN')) {
                            throw new Error('Mobile number is not valid');
                          }
                        },
                      },
                    ],
                  })(<Input placeholder="Mobile" />)}
                </Form.Item>
              ),
            },
            {
              dataIndex: 'action',
              render: (data, record) => (
                <Button
                  className="opacity-25 hover:opacity-100"
                  icon="delete"
                  onClick={() => {
                    handleTrafficUserDelete(record.id);
                  }}
                />
              ),
            },
          ]}
        />
        <Button
          htmlType="button"
          icon="user-add"
          onClick={handleTrafficUserAdd}
        >
          Add User
        </Button>
      </div>

      <Button
        type="primary"
        htmlType="submit"
        className="w-full"
        loading={loading}
      >
        Submit
      </Button>
    </Form>
  );
};

export default Form.create<Props>({ name: 'createSection' })(CreateSectionForm);
