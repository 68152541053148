import React, { cloneElement, useState, useCallback } from 'react';
import { Drawer } from 'antd';

import CreateSectionForm from './CreateSectionForm';
import { Section } from '../../../types';

interface Props {
  trigger: JSX.Element;
  onCreate?: (sectionCreated: Section) => void;
}

const CreateSection: React.FC<Props> = ({ trigger, onCreate }) => {
  const [drawerOpened, setDrawerOpened] = useState(false);

  const handleDrawerOpen = useCallback(() => {
    setDrawerOpened(true);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setDrawerOpened(false);
  }, []);

  const handleSectionCreate = useCallback(
    (sectionCreated: Section) => {
      setDrawerOpened(false);
      if (onCreate) {
        onCreate(sectionCreated);
      }
    },
    [onCreate],
  );

  return (
    <>
      {cloneElement(trigger, { onClick: handleDrawerOpen })}
      <Drawer
        visible={drawerOpened}
        onClose={handleDrawerClose}
        title="Create Section"
        width={640}
        destroyOnClose
        maskClosable={false}
        bodyStyle={{ height: 'calc(100% - 55px)' }}
      >
        <CreateSectionForm onCreate={handleSectionCreate} />
      </Drawer>
    </>
  );
};

export default CreateSection;
