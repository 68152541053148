import { useEffect, useState } from 'react';
import Axios, { CancelTokenSource } from 'axios';

import useApi from './useApi';

const useData = <P extends object>(apiEndpoint: string) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<P>();
  const [error, setError] = useState<string>();

  const api = useApi();

  useEffect(() => {
    // const source = Axios.CancelToken.source();
    let source: null | CancelTokenSource = null;

    const fetchData = async () => {
      setLoading(true);
      try {
        source = Axios.CancelToken.source();
        const { data: responseData } = await api.get(apiEndpoint, {
          cancelToken: source.token,
        });
        setData(responseData);
        setLoading(false);
      } catch (e) {
        if (!Axios.isCancel(e)) {
          // TODO: Get human readable message from response
          setError('Something went wrong');
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      if (source) {
        source.cancel();
      }
    };
  }, [api, apiEndpoint]);

  return {
    loading,
    data,
    error,
    setData,
  };
};

export default useData;
