import React, { useState } from 'react';
import styled, { withTheme } from 'styled-components';
import { Typography, DatePicker, Empty } from 'antd';
import moment from 'moment';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';

import { orderBy } from 'lodash-es';
import { Station } from '../../../types';
import usePoll from '../../../hooks/usePoll';
import Loader from '../../../components/Loader';
import { Theme } from '../../../styles/theme';

const tags = [
  {
    label: '0-5 Mins',
    value: '0-5mins',
  },
  {
    label: '5-10 Mins',
    value: '5-10mins',
  },
  {
    label: '10-15 Mins',
    value: '10-15mins',
  },
  {
    label: '15-20 Mins',
    value: '15-20mins',
  },
  {
    label: '20-25 Mins',
    value: '20-25mins',
  },
  {
    label: '25-30 Mins',
    value: '25-30mins',
  },
  {
    label: '30+ Mins',
    value: '30mins+',
  },
];

interface DurationStats {
  key: 'string';
  value: [{ station: Station; count: number }];
}

interface Props {
  theme: Theme;
}

const RequestDurationChart: React.FC<Props> = ({ theme }) => {
  const [range, setRange] = useState<[moment.Moment, moment.Moment]>([
    moment().subtract(1, 'day'),
    moment(),
  ]);

  const [activeTag, setActiveTag] = useState(tags[0].value);

  const { loading, data: durationStats } = usePoll<Array<DurationStats>>(
    `/otp/analysis/duration?startDate=${range[0].unix() *
      1000}&endDate=${range[1].unix() * 1000}`,
  );

  return (
    <>
      <Typography.Paragraph strong>Request Duration</Typography.Paragraph>
      <StyledRangePicker
        value={range}
        onChange={dates => {
          if (dates.length > 0) {
            setRange([dates[0] as moment.Moment, dates[1] as moment.Moment]);
          } else {
            setRange([moment().subtract(1, 'day'), moment()]);
          }
        }}
      />
      <Container>
        <ChartContainer>
          {(() => {
            if (loading) {
              return <Loader text="Loading Data..." />;
            }

            if (durationStats) {
              const activeDurationStats = durationStats.find(
                durationStat => durationStat.key === activeTag,
              );
              if (activeDurationStats) {
                const barChartData = orderBy(
                  activeDurationStats.value.map(stat => ({
                    stationName: stat.station.code,
                    count: stat.count,
                  })),
                  item => item.stationName,
                );

                return (
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      data={barChartData}
                      layout="vertical"
                      barSize={8}
                      margin={{
                        left: -8,
                        right: 8,
                      }}
                    >
                      <Tooltip
                        contentStyle={{
                          fontSize: 12,
                        }}
                        labelStyle={{
                          fontWeight: 'bold',
                        }}
                      />
                      <CartesianGrid horizontal={false} strokeOpacity={0.5} />
                      <Bar
                        dataKey="count"
                        fill={theme.primaryColor}
                        name="Count"
                      />
                      <YAxis
                        dataKey="stationName"
                        type="category"
                        axisLine={false}
                        tick={{ fontSize: 10 }}
                      />
                      <XAxis type="number" allowDecimals={false} />
                    </BarChart>
                  </ResponsiveContainer>
                );
              }

              return <StyledEmpty />;
            }

            return null;
          })()}
        </ChartContainer>
        <TagsContainer>
          {tags.map(tag => (
            <Tag
              key={tag.value}
              active={tag.value === activeTag}
              onClick={() => {
                setActiveTag(tag.value);
              }}
            >
              {tag.label}
            </Tag>
          ))}
        </TagsContainer>
      </Container>
    </>
  );
};

export default withTheme(RequestDurationChart);

const StyledRangePicker = styled(DatePicker.RangePicker)`
  &&& {
    display: inline-block;
    margin-bottom: 8px;
  }
`;

const Container = styled.div`
  padding: 8px;
  height: 560px;
  background-color: ${({ theme }) => theme.lightBackgroundColor};
  display: flex;
  flex-direction: column;
`;

const ChartContainer = styled.div`
  flex: 1;
  position: relative;

  & .recharts-text.recharts-cartesian-axis-tick-value {
    font-size: 12px;
  }

  &&& {
    & .recharts-legend-item {
      display: inline-flex !important;
      align-items: center;
    }
  }
`;

const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Tag = styled.div<{ active: boolean }>`
  margin: 4px;
  padding: 4px;
  border-radius: ${({ theme }) => theme.borderRadius};
  font-size: 12px;
  border: 1px solid ${({ theme }) => theme.primaryColor};
  color: ${({ theme, active }) =>
    active ? theme.inverseTextColor : theme.textColor};
  background-color: ${({ theme, active }) =>
    active ? theme.primaryColor : 'transparent'};
  cursor: pointer;
`;

const StyledEmpty = styled(Empty)`
  &&& {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;
