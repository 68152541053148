import React, { useState, useCallback } from 'react';
import { Popconfirm, Icon, message } from 'antd';
import { get } from 'lodash-es';
import useApi from '../../../hooks/useApi';

interface Props {
  trigger: JSX.Element;
  sectionId: string;
  onDelete?: () => void;
}

const DeleteSection: React.FC<Props> = ({ trigger, sectionId, onDelete }) => {
  const api = useApi();

  const [loading, setLoading] = useState(false);

  const handleConfirmDelete = useCallback(async () => {
    setLoading(true);
    try {
      await api.delete(`/sections/${sectionId}`);
      if (onDelete) {
        onDelete();
      }
    } catch (error) {
      const errorMessage = get(
        error,
        'response.data.message',
        'Something went wrong. Please try again',
      );
      message.error(errorMessage);
      setLoading(false);
    }
  }, [api, onDelete, sectionId]);

  return (
    <Popconfirm
      title="Section once deleted cannot be recovered. Do you want to continue?"
      icon={loading ? <Icon type="loading" spin /> : undefined}
      onConfirm={handleConfirmDelete}
    >
      {trigger}
    </Popconfirm>
  );
};

export default DeleteSection;
