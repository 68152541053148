import React, { useContext } from 'react';
import {
  RouteProps,
  Route as ReactRouterRoute,
  Redirect,
} from 'react-router-dom';

import AuthContext from '../../contexts/AuthContext';

interface Props extends RouteProps {
  protectedRoute?: boolean;
}

const Route: React.FC<Props> = ({ protectedRoute, ...routeProps }) => {
  const { authToken, user } = useContext(AuthContext);

  if (protectedRoute && !(authToken && user)) {
    return <Redirect to={{ pathname: '/login' }} />;
  }

  return <ReactRouterRoute {...routeProps} />;
};

Route.defaultProps = {
  protectedRoute: false,
};

export default Route;
