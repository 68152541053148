import { red, green, gold } from '@ant-design/colors';

const theme = {
  primaryColor: '#1890ff',
  darkBackgroundColor: '#202020',
  lightBackgroundColor: '#ffffff',
  triggerBackground: '#343434',
  bodyBackgroundColor: '#F0F2F5',
  menuDarkSubmenuBackground: '#121212',
  borderRadius: '4px',
  cardBorderRadius: '2px',
  errorColor: red[6],
  successColor: green[7],
  warningColor: gold[6],
  normalColor: '#d9d9d9',
  textColor: 'rgba(0, 0, 0, 0.65)',
  headingColor: 'rgba(0, 0, 0, 0.85)',
  inverseTextColor: '#fff',
  borderBaseColor: '#D9D9D9',
  borderSplitColor: '#E8E8E8',
};

export default theme;

export type Theme = typeof theme;

export type themeColor =
  | 'primaryColor'
  | 'successColor'
  | 'warningColor'
  | 'errorColor'
  | 'primaryColor';
