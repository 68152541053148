import React, { useRef, useState } from 'react';
import styled, { withTheme } from 'styled-components';
import {
  Table,
  Typography,
  Icon,
  DatePicker,
  Button,
  Input,
  Checkbox,
} from 'antd';
import moment from 'moment';
import { orderBy } from 'lodash-es';
import Helmet from 'react-helmet';
import qs from 'querystring';
import { FilterDropdownProps } from 'antd/lib/table';

import AppShell from '../../components/AppShell';
import { RelayRoomRequest, Station } from '../../types';
import { Theme } from '../../styles/theme';
import usePoll from '../../hooks/usePoll';
import useData from '../../hooks/useData';
import DownloadData from './components/DownloadData';

const computeDuration = (startTime: number, stopTime: number) => {
  const secondsDiff = (stopTime - startTime) / 1000; // as the start time and stop time would be in microseconds

  const hours = Math.floor(secondsDiff / 3600);
  if (hours > 0) {
    const mins = Math.floor((secondsDiff - hours * 3600) / 60);
    return `${hours} ${hours > 1 ? 'hrs' : 'hr'} ${mins} m`;
  }

  const mins = Math.floor(secondsDiff / 60);
  if (mins > 0) {
    return `${Math.floor(secondsDiff / 60)}m`;
  }

  return `${Math.floor(secondsDiff)}s`;
};

interface Props {
  theme: Theme;
}

const OTPTable: React.FC<Props> = ({ theme }) => {
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const { data: stations } = useData<Array<Station>>('/stations');

  const [stationsSelected, setStationsSelected] = useState<Array<string>>([]);
  const [purposesSelected, setPurposesSelected] = useState<Array<string>>([]);
  const [timePeriod, setTimePeriod] = useState<
    [moment.Moment, moment.Moment] | undefined
  >(undefined);
  const [durationSelected, setDurationSelected] = useState<Array<string>>([]);
  const [openOTP, setOpenOTP] = useState<string | undefined>(undefined);
  const [closeOTP, setCloseOTP] = useState<string | undefined>(undefined);
  const [designation, setDesignation] = useState<string | undefined>(undefined);
  const [mobile, setMobile] = useState<string | undefined>(undefined);

  const { loading, data: otpHistory } = usePoll<{
    data: Array<RelayRoomRequest>;
    stats: { totalPages: number; totalItems: number };
  }>(
    `/otp/history?${qs.stringify({
      page: pageIndex,
      limit: pageSize,
      sort: 'createdAt',
      order: 'desc',
      ...(stationsSelected.length > 0
        ? {
            stations: stationsSelected.join(','),
          }
        : undefined),
      ...(purposesSelected.length > 0
        ? {
            purpose: purposesSelected.join(','),
          }
        : undefined),
      ...(timePeriod
        ? {
            from: timePeriod
              ? timePeriod[0]
                  .clone()
                  .startOf('day')
                  .valueOf()
              : undefined,
            to: timePeriod
              ? timePeriod[1]
                  .clone()
                  .endOf('day')
                  .valueOf()
              : undefined,
          }
        : {}),
      ...(durationSelected.length > 0
        ? {
            duration: durationSelected.join(','),
          }
        : undefined),
      ...(designation
        ? {
            designation: designation.trim(),
          }
        : undefined),
      ...(openOTP
        ? {
            openOTP: openOTP.trim(),
          }
        : undefined),
      ...(closeOTP
        ? {
            closeOTP: closeOTP.trim(),
          }
        : undefined),
      ...(mobile
        ? {
            mobile: `91${mobile.trim()}`,
          }
        : undefined),
    })}`,
  );

  const designationSearchInput = useRef<any>();
  const mobileSearchInput = useRef<any>();
  const openOTPSearchInput = useRef<any>();
  const closeOTPSearchInput = useRef<any>();

  const dateRangeFilter = {
    filterIcon: (filtered: boolean) => (
      <Icon
        type="calendar"
        style={{ color: filtered ? theme.primaryColor : undefined }}
      />
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: {
      selectedKeys?: Array<string>;
      setSelectedKeys: (selectedKeys: Array<string>) => void;
      confirm: () => void;
      clearFilters: (selectedKeys: Array<string>) => void;
    }) => {
      const startDate =
        selectedKeys && selectedKeys.length > 0
          ? moment(selectedKeys[0].split('|')[0])
          : undefined;

      const endDate =
        selectedKeys && selectedKeys.length > 0
          ? moment(selectedKeys[0].split('|')[1])
          : undefined;

      return (
        <div className="p-2">
          <div className="mb-2">
            <DatePicker.RangePicker
              className="w-64"
              value={startDate && endDate ? [startDate, endDate] : undefined}
              onChange={dates => {
                if (setSelectedKeys && dates) {
                  if (dates.length > 0 && dates[0] && dates[1]) {
                    setSelectedKeys([
                      `${moment(dates[0]).toISOString()}|${moment(
                        dates[1],
                      ).toISOString()}`,
                    ]);
                  } else {
                    setSelectedKeys([]);
                  }
                }
              }}
            />
          </div>
          <div className="-mx-1 flex items-center">
            <Button
              type="primary"
              onClick={() => {
                if (startDate && endDate) {
                  setTimePeriod([startDate, endDate]);
                }
                confirm();
              }}
              icon="search"
              size="small"
              className="mx-1 w-1/2"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                if (clearFilters && selectedKeys) {
                  setTimePeriod(undefined);
                  clearFilters(selectedKeys);
                }
              }}
              size="small"
              className="mx-1 w-1/2"
            >
              Reset
            </Button>
          </div>
        </div>
      );
    },
  };

  const searchFilter = (
    ref: React.RefObject<any>,
    onChange: (searchString: string | undefined) => void,
  ) => ({
    filterIcon: (filtered: boolean) => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: {
      selectedKeys?: Array<string>;
      setSelectedKeys: (selectedKeys: Array<string>) => void;
      confirm: () => void;
      clearFilters: (selectedKeys: Array<string>) => void;
    }) => {
      return (
        <div className="p-2">
          <Input
            ref={ref}
            autoFocus
            value={(selectedKeys || [])[0]}
            onChange={event => {
              if (setSelectedKeys) {
                setSelectedKeys(event.target.value ? [event.target.value] : []);
              }
            }}
            placeholder="Search Sender Number"
            style={{
              width: 188,
              marginBottom: 8,
              display: 'block',
            }}
            onPressEnter={() => {
              confirm();
              if (selectedKeys && selectedKeys.length > 0) {
                onChange(selectedKeys[0]);
              }
            }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
              if (selectedKeys && selectedKeys.length > 0) {
                onChange(selectedKeys[0]);
              }
            }}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              if (clearFilters && selectedKeys) {
                clearFilters(selectedKeys);
              }
              onChange(undefined);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      );
    },
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => {
          ref.current.select();
        });
      }
    },
  });

  const optionsFilter = ({
    options,
    onChange,
  }: {
    options: Array<{ text: string; value: string }>;
    onChange: (optionsSelected: Array<string>) => void;
  }): ((props: FilterDropdownProps) => React.ReactNode) => {
    return ({ selectedKeys, setSelectedKeys, confirm, clearFilters }) => {
      return (
        <div>
          <div className="py-1 max-h-80 overflow-auto border-b">
            {options.map(option => (
              <div key={option.value} className="px-3 py-1">
                <Checkbox
                  className="mr-2"
                  checked={selectedKeys && selectedKeys.includes(option.value)}
                  onChange={event => {
                    if (setSelectedKeys) {
                      if (event.target.checked) {
                        if (selectedKeys) {
                          setSelectedKeys([
                            ...(selectedKeys as Array<string>),
                            option.value,
                          ]);
                        } else {
                          setSelectedKeys([option.value]);
                        }
                      } else if (selectedKeys) {
                        setSelectedKeys(
                          selectedKeys.filter(
                            key => key !== option.value,
                          ) as Array<string>,
                        );
                      } else {
                        setSelectedKeys([]);
                      }
                    }
                  }}
                />
                <span>{option.text}</span>
              </div>
            ))}
          </div>
          <div className="flex items-center justify-between p-2">
            <Button
              style={{ padding: 0 }}
              type="link"
              onClick={() => {
                if (confirm) {
                  confirm();
                }
                if (selectedKeys) {
                  onChange(selectedKeys as Array<string>);
                }
              }}
            >
              OK
            </Button>
            <Button
              style={{ padding: 0 }}
              type="link"
              onClick={() => {
                if (clearFilters) {
                  clearFilters();
                }
                onChange([]);
              }}
            >
              Reset
            </Button>
          </div>
        </div>
      );
    };
  };

  return (
    <>
      <Helmet>
        <title>OTP Requests</title>
      </Helmet>
      <AppShell title="Dashboard">
        <div className="flex flex-col h-full p-4">
          <div className="flex justify-end mb-4">
            <DownloadData
              trigger={
                <Button type="primary" icon="download">
                  Download Data
                </Button>
              }
            />
          </div>
          <TableContainer>
            <Table
              loading={loading}
              bordered
              pagination={{
                current: pageIndex,
                onChange: pageNumber => {
                  setPageIndex(pageNumber);
                },
                pageSize,
                pageSizeOptions: ['10', '25', '50', '100'],
                onShowSizeChange: (current, size) => {
                  setPageSize(size);
                },
                total: otpHistory ? otpHistory.stats.totalItems : undefined,
                showSizeChanger: true,
              }}
              rowKey="_id"
              dataSource={
                otpHistory
                  ? orderBy(
                      otpHistory.data,
                      otp => otp.openRequest.createdAt,
                      'desc',
                    )
                  : []
              }
              columns={[
                {
                  dataIndex: 'sNo',
                  title: <Typography.Text>S.No</Typography.Text>,
                  width: 48,
                  render: (text, record, index) => (
                    <Typography.Text>
                      {(pageIndex - 1) * pageSize + index + 1}
                    </Typography.Text>
                  ),
                },
                {
                  dataIndex: 'station',
                  title: 'Station',
                  width: 96,
                  render: (text, record) => (
                    <Typography.Text>
                      {record.station.code.toUpperCase()}
                    </Typography.Text>
                  ),
                  filterDropdown: stations
                    ? optionsFilter({
                        options: orderBy(stations, station => station.code).map(
                          station => ({
                            text: station.code,
                            value: station._id,
                          }),
                        ),
                        onChange: setStationsSelected,
                      })
                    : undefined,
                },
                {
                  dataIndex: 'date',
                  title: 'Date',
                  width: 96,
                  render: (text, record) => (
                    <Typography.Text>
                      {moment(record.openRequest.createdAt).format(
                        'DD MMM YYYY',
                      )}
                    </Typography.Text>
                  ),
                  ...dateRangeFilter,
                },
                {
                  title: 'Sender',
                  children: [
                    {
                      dataIndex: 'user.designation',
                      title: 'Designation',
                      width: 136,
                      render: (text, record) => (
                        <Typography.Text strong>
                          {record.user.designation.toUpperCase()}
                        </Typography.Text>
                      ),
                      ...searchFilter(designationSearchInput, setDesignation),
                    },
                    {
                      dataIndex: 'user.mobile',
                      title: 'Mobile No',
                      width: 136,
                      render: (text, record) => (
                        <Typography.Text>
                          {record.user.mobile.substr(2)}
                        </Typography.Text>
                      ),
                      ...searchFilter(mobileSearchInput, setMobile),
                    },
                  ],
                },
                {
                  title: 'Open Request',
                  width: 200,
                  children: [
                    {
                      title: 'Opened At',
                      dataIndex: 'openedAt',
                      width: 100,
                      render: (text, record) => (
                        <Typography.Text>
                          {moment(record.openRequest.createdAt).format(
                            'HH:mm a',
                          )}
                        </Typography.Text>
                      ),
                    },
                    {
                      title: 'OTP',
                      dataIndex: 'openedOTP',
                      width: 100,
                      render: (text, record) => (
                        <Typography.Text>
                          {record.openRequest.otp}
                        </Typography.Text>
                      ),
                      ...searchFilter(openOTPSearchInput, setOpenOTP),
                    },
                  ],
                },
                {
                  title: 'Close Request',
                  width: 200,
                  children: [
                    {
                      title: 'Closed At',
                      dataIndex: 'closedAt',
                      width: 100,
                      render: (text, record) =>
                        record.closeRequest ? (
                          <Typography.Text>
                            {moment(record.closeRequest.createdAt)
                              .startOf('day')
                              .unix() ===
                            moment(record.openRequest.createdAt)
                              .startOf('day')
                              .unix()
                              ? moment(record.closeRequest.createdAt).format(
                                  'HH:mm a',
                                )
                              : moment(record.closeRequest.createdAt).format(
                                  'DD MMM YYYY HH:mm a',
                                )}
                          </Typography.Text>
                        ) : (
                          '-'
                        ),
                    },
                    {
                      title: 'OTP',
                      dataIndex: 'closedOTP',
                      width: 100,
                      render: (text, record) =>
                        record.closeRequest ? (
                          <Typography.Text>
                            {record.closeRequest.otp}
                          </Typography.Text>
                        ) : (
                          '-'
                        ),
                      ...searchFilter(closeOTPSearchInput, setCloseOTP),
                    },
                  ],
                },
                {
                  title: 'Purpose',
                  dataIndex: 'purpose',
                  render: (text, record) => (
                    <Typography.Text>{record.purpose.message}</Typography.Text>
                  ),
                  filterDropdown: optionsFilter({
                    options: [
                      {
                        text:
                          'For attending failures of S&T gears from within relay rooms.',
                        value: '1',
                      },
                      {
                        text: 'For periodical maintenance of Relay Rooms.',
                        value: '2',
                      },
                      {
                        text: 'For special activities and works in RR',
                        value: '3',
                      },
                      { text: 'Emergency cases.', value: '4' },
                    ],
                    onChange: setPurposesSelected,
                  }),
                },
                {
                  title: 'Duration',
                  dataIndex: 'duration',
                  width: 96,
                  render: (text, record) =>
                    record.closeRequest ? (
                      <Typography.Text>
                        {computeDuration(
                          record.openRequest.createdAt,
                          record.closeRequest.createdAt,
                        )}
                      </Typography.Text>
                    ) : (
                      '-'
                    ),
                  filterDropdown: optionsFilter({
                    options: [
                      {
                        text: '0-5 minutes',
                        value: '0-5',
                      },
                      {
                        text: '6-10 minutes',
                        value: '6-10',
                      },
                      {
                        text: '11-15 minutes',
                        value: '11-15',
                      },
                      {
                        text: '16-20 minutes',
                        value: '16-20',
                      },
                      {
                        text: '21-25 minutes',
                        value: '21-25',
                      },
                      {
                        text: '26-30 minutes',
                        value: '26-30',
                      },
                      {
                        text: 'more than 30 minutes',
                        value: '31-Infinity',
                      },
                    ],
                    onChange: setDurationSelected,
                  }),
                },
              ]}
            />
          </TableContainer>
        </div>
      </AppShell>
    </>
  );
};

export default withTheme(OTPTable);

const TableContainer = styled.div`
  flex: 1;

  &&& {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      padding: 16px 8px;
    }
  }
`;
