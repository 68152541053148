import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { Layout, Menu, Icon, Typography, Avatar, Dropdown } from 'antd';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { shadows } from '../../styles/common';
import AuthContext from '../../contexts/AuthContext';
import useData from '../../hooks/useData';
import { Organisation } from '../../types';

const MENU_ITEMS: Array<{
  id: string;
  icon: { type?: string; component?: any };
  name: string;
  url: string;
  selectedURLPatterns: Array<RegExp>;
}> = [
  {
    id: 'dashboard',
    icon: {
      type: 'alert',
    },
    name: 'Dashboard',
    url: '/dashboard',
    selectedURLPatterns: [/dashboard/],
  },
  {
    id: 'analytics',
    icon: {
      type: 'dashboard',
    },
    name: 'Analytics',
    url: '/analytics',
    selectedURLPatterns: [/analytics/],
  },
  {
    id: 'registration',
    icon: {
      type: 'container',
    },
    name: 'Registration',
    url: '/registration',
    selectedURLPatterns: [/registration/],
  },
];

interface Props extends RouteComponentProps {
  title?: string;
}

const AppShell: React.FC<Props> = ({
  children,
  location: { pathname },
  title,
}) => {
  const [collapsed, setCollapsed] = useState(false);

  const { signOut } = useContext(AuthContext);

  const { data: userInfo } = useData<{ organisation: Organisation }>(
    '/accounts/me',
  );

  return (
    <Layout hasSider>
      <StyledLayoutSider
        collapsible
        collapsed={collapsed}
        width={200}
        onCollapse={collapsedState => {
          setCollapsed(collapsedState);
        }}
      >
        <StyledMenu
          theme="dark"
          mode="inline"
          selectedKeys={MENU_ITEMS.filter(item => {
            return item.selectedURLPatterns.some(pattern =>
              pattern.test(pathname),
            );
          }).map(item => item.id)}
        >
          {MENU_ITEMS.map(item => {
            return (
              <Menu.Item key={item.id}>
                <Link to={item.url}>
                  <Icon type={item.icon.type} component={item.icon.component} />
                  <span>{item.name}</span>
                </Link>
              </Menu.Item>
            );
          })}
        </StyledMenu>
      </StyledLayoutSider>
      <Layout
        style={{
          marginLeft: collapsed ? 80 : 200,
        }}
      >
        <StyledLayoutHeader
          style={{
            width: `calc(100% - ${collapsed ? 80 : 200}px)`,
          }}
        >
          {title && <StyledTitle level={4}>{title}</StyledTitle>}
          <Spacer />
          <Dropdown
            trigger={['click']}
            overlay={
              <Menu>
                {userInfo
                  ? [
                      // eslint-disable-next-line react/jsx-indent
                      <Menu.Item key="userName">
                        <Link to="/registration">
                          {userInfo.organisation.name}
                        </Link>
                      </Menu.Item>,
                      // eslint-disable-next-line react/jsx-indent
                      <Menu.Divider key="divider" />,
                    ]
                  : null}
                <Menu.Item onClick={signOut}>Logout</Menu.Item>
              </Menu>
            }
          >
            <Avatar icon="user" />
          </Dropdown>
        </StyledLayoutHeader>
        <StyledLayoutContent>{children}</StyledLayoutContent>
      </Layout>
    </Layout>
  );
};

export default withRouter(AppShell);

const StyledLayoutSider = styled(Layout.Sider)`
  && {
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
    transition: none;
    z-index: 1;
  }
`;

const StyledLayoutHeader = styled(Layout.Header)`
  && {
    background-color: ${({ theme }) => theme.lightBackgroundColor};
    position: fixed;
    z-index: 1;
    height: 48px;
    padding: 16px;
    display: flex;
    align-items: center;
    box-shadow: ${shadows.shadow1Down};

    & > * + * {
      margin-left: 24px;
    }
  }
`;

const StyledLayoutContent = styled(Layout.Content)`
  margin-top: 48px;
  height: calc(100vh - 48px);
  overflow: auto;
  transform: translateZ(0);
`;

const StyledMenu = styled(Menu)`
  &&& {
    height: 100%;
  }
`;

const StyledTitle = styled(Typography.Title)`
  &&& {
    margin: 0;
    font-size: 16px;
  }
`;

const Spacer = styled.div`
  flex: 1;
`;
