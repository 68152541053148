import React, { FormEvent, useContext, useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import Helmet from 'react-helmet';
import { Redirect } from 'react-router-dom';

import AuthContext from '../../contexts/AuthContext';

interface Props {
  form: WrappedFormUtils;
}

const Login: React.FC<Props> = ({
  form: { getFieldDecorator, validateFields },
}) => {
  const [loading, setLoading] = useState(false);

  const { signInWithEmail, user, authToken } = useContext(AuthContext);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    validateFields(async (fieldErrors, values) => {
      if (!fieldErrors) {
        setLoading(true);
        try {
          const { email, password } = values;
          await signInWithEmail(email, password);
        } catch (error) {
          const errorMessage = 'Something went wrong. Please try again';
          message.error(errorMessage);
          setLoading(false);
        }
      }
    });
  };

  if (user && authToken) {
    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <div className="w-full h-screen flex">
        {/* Description Panel in larger devices (tab+) */}
        <div className="hidden flex-1 md:flex items-center justify-center relative">
          <img
            src={require('../../images/login.jpg')}
            alt="Login"
            className="absolute inset-0 object-cover w-full h-full"
          />
          <div className="w-80 xl:w-150 relative z-10">
            <h1 className="text-red-100 text-5xl font-medium">
              OTP <span className="font-thin">based</span> Relay Room Access{' '}
              <span className="font-thin">&</span> Monitoring System
            </h1>
            <div className="text-lg font-light text-red-300 tracking-wide flex items-center">
              <span>Powered by</span>
              <span className="inline mx-2">
                <img
                  src={require('../../images/logo.svg')}
                  alt="BOCS"
                  className="w-6 h-6"
                />
              </span>
              <span className="font-medium">BOCS</span>
            </div>
          </div>
        </div>

        {/* Form Container */}
        <div className="flex-1 bg-dark-background-color shadow-inner flex flex-col items-center justify-center relative">
          {/* Background image in mobile */}
          <img
            src={require('../../images/login.jpg')}
            alt="Login"
            className="absolute inset-0 object-cover w-full h-full md:hidden"
          />

          <div className="w-80 relative z-10">
            {/* Description in mobile */}
            <h1 className="text-red-100 text-3xl font-medium md:hidden">
              OTP <span className="font-thin">based</span> Relay Room Access{' '}
              <span className="font-thin">&</span> Monitoring System
            </h1>
            <div className="text-sm font-light text-red-300 tracking-wide flex items-center mb-4 md:hidden">
              <span>Powered by</span>
              <span className="inline mx-2">
                <img
                  src={require('../../images/logo.svg')}
                  alt="BOCS"
                  className="w-4 h-4"
                />
              </span>
              <span className="font-medium">BOCS</span>
            </div>

            {/* Form Container */}
            <div className="p-4 rounded bg-white">
              <h2 className="text-lg font-medium mb-4">Login</h2>
              <Form onSubmit={handleSubmit}>
                <Form.Item label="Email">
                  {getFieldDecorator('email', {
                    validateTrigger: 'onBlur',
                    validateFirst: true,
                    rules: [
                      {
                        required: true,
                        message: 'Email is required',
                      },
                    ],
                  })(<Input placeholder="Email" />)}
                </Form.Item>
                <Form.Item label="Password">
                  {getFieldDecorator('password', {
                    rules: [
                      { required: true, message: 'Password is required' },
                    ],
                  })(<Input.Password placeholder="Password" type="password" />)}
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    className="w-full"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Form.create({ name: 'login' })(Login);
