import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';

interface Option {
  value: string;
  label: string;
}

export interface Props extends SelectProps {
  fetchData: () => Promise<Array<Option>>;
}

interface State {
  loading: boolean;
  data: Array<Option>;
}

class AsyncSelect extends React.Component<Props, State> {
  state = {
    loading: true,
    data: [],
  };

  componentDidMount() {
    this.fetchOptions();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.fetchData !== this.props.fetchData) {
      this.fetchOptions();
    }
  }

  fetchOptions = async () => {
    this.setState({ loading: true });
    try {
      const { fetchData } = this.props;
      const data = await fetchData();
      this.setState({ data });
    } catch (error) {
      this.setState({ data: [] });
    } finally {
      this.setState({ loading: false });
    }
  };

  filterOption = (input: string, option: any) =>
    (option.props.children as string)
      .toLowerCase()
      .indexOf(input.toLowerCase()) >= 0;

  render() {
    const { loading, data } = this.state;
    const { value, ...restProps } = this.props;

    return (
      <Select
        loading={loading}
        value={loading ? undefined : value}
        showSearch
        filterOption={this.filterOption}
        {...restProps}
      >
        {data.map((item: Option) => (
          <Select.Option key={item.value} value={item.value}>
            {item.label}
          </Select.Option>
        ))}
      </Select>
    );
  }
}

export default AsyncSelect;
