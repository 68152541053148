import React, { useMemo, useCallback, useState } from 'react';
import { Icon, Button, Empty, Drawer } from 'antd';
import classNames from 'classnames';
import Helmet from 'react-helmet';

import AppShell from '../../components/AppShell';
import CreateSection from './components/CreateSection';
import useData from '../../hooks/useData';
import { Section } from '../../types';
import EditSectionForm from './components/EditSectionForm';
import DeleteSection from './components/DeleteSection';
import Loader from '../../components/Loader';

const Registration = () => {
  const { loading, data: sections, setData: setSections } = useData<
    Array<Section>
  >('/sections');

  const [editSection, setEditSection] = useState<string | undefined>(undefined);

  const editSectionData = sections
    ? sections.find(section => section._id === editSection)
    : undefined;

  const handleEditDrawerClose = useCallback(() => {
    setEditSection(undefined);
  }, []);

  const handleSectionCreate = useCallback(
    (sectionCreated: Section) => {
      setSections(sectionsState =>
        sectionsState ? [...sectionsState, sectionCreated] : undefined,
      );
    },
    [setSections],
  );

  const handleSectionEdit = useCallback(
    (sectionEdited: Section) => {
      setSections(sectionsState =>
        sectionsState
          ? sectionsState.map(section =>
              section._id === sectionEdited._id ? sectionEdited : section,
            )
          : undefined,
      );
      setEditSection(undefined);
    },
    [setSections],
  );

  const handleSectionDelete = useCallback(
    (sectionDeleted: string) => {
      setSections(sectionsState =>
        sectionsState
          ? sectionsState.filter(section => section._id === sectionDeleted)
          : undefined,
      );
    },
    [setSections],
  );

  const content = useMemo(() => {
    if (loading) {
      return (
        <div className="p-4 h-full flex items-center justify-center relative">
          <Loader text="Loading Sections..." />
        </div>
      );
    }

    if (sections) {
      if (sections.length === 0) {
        return (
          <div className="p-4 h-full flex items-center justify-center">
            <Empty description="No section created" />
          </div>
        );
      }

      return (
        <div className="flex flex-col h-full">
          <div className="flex items-center">
            <div className="text-xs uppercase tracking-wide font-medium text-caption flex-1 px-4 py-2 bg-light-primary-color text-primary-color">
              Name
            </div>
            <div className="text-xs uppercase tracking-wide font-medium text-caption flex-1 px-4 py-2 bg-light-primary-color text-primary-color">
              Stations
            </div>
            <div className="text-xs uppercase tracking-wide font-medium text-caption flex-1 px-4 py-2 bg-light-primary-color text-primary-color">
              SnT Users
            </div>
            <div className="text-xs uppercase tracking-wide font-medium text-caption flex-1 px-4 py-2 bg-light-primary-color text-primary-color">
              Traffic Users
            </div>
          </div>
          <div className="flex-1 overflow-auto">
            {sections.map(section => (
              <div key={section._id} className="flex border-b">
                <div className="flex-1 px-4 py-4 border-r">
                  <div className="font-medium text-primary-color mb-1">
                    {section.name}
                  </div>
                  <div className="flex items-center opacity-50 hover:opacity-100">
                    <button
                      className="text-xs mr-2 text-caption-color cursor-pointer"
                      type="button"
                      onClick={() => {
                        setEditSection(section._id);
                      }}
                    >
                      Edit
                    </button>
                    <span className="text-xs mr-2 text-caption-color">|</span>
                    <DeleteSection
                      trigger={
                        <span className="text-xs text-caption-color cursor-pointer">
                          Delete
                        </span>
                      }
                      sectionId={section._id}
                      onDelete={() => {
                        handleSectionDelete(section._id);
                      }}
                    />
                  </div>
                </div>
                <div className="flex-1 px-4 py-4 border-r">
                  <div className="flex flex-wrap -m-1">
                    {section.stations.map(station => (
                      <div
                        className="py-1 px-2 text-xs uppercase border border-primary-color bg-light-primary-color text-primary-color rounded m-1"
                        key={station.code}
                      >
                        {station.code}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex-1 px-4 py-4 border-r">
                  {section.sntUsers.map((user, index) => (
                    <div
                      key={user._id}
                      className={classNames(
                        index !== section.sntUsers.length - 1
                          ? 'mb-2'
                          : undefined,
                      )}
                    >
                      <div className="text-xs text-heading-color font-medium">
                        {user.name}
                      </div>
                      <div className="text-xs text-text-color">
                        <span className="font-medium">
                          {user.designation.toUpperCase()}
                        </span>{' '}
                        | +{user.mobile}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="flex-1 px-4 py-4 border-r">
                  {section.trafficUsers.map((user, index) => (
                    <div
                      key={user._id}
                      className={classNames(
                        index !== section.trafficUsers.length - 1
                          ? 'mb-2'
                          : undefined,
                      )}
                    >
                      <div className="text-xs text-heading-color font-medium">
                        {user.name}
                      </div>
                      <div className="text-xs text-text-color">
                        <span className="font-medium">
                          {user.designation.toUpperCase()}
                        </span>{' '}
                        | +{user.mobile}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }

    return null;
  }, [handleSectionDelete, loading, sections]);

  return (
    <>
      <Helmet>
        <title>Sections Registration</title>
      </Helmet>
      <AppShell title="Registration">
        <div className="h-full">
          <div className="max-w-4xl mx-auto py-4 flex flex-col h-full">
            <div className="text-heading-color font-medium text-2xl mb-4">
              Sections
            </div>
            <div className="rounded bg-white shadow flex flex-col flex-1">
              <div className="flex items-center justify-between px-4 py-3 border-b">
                <div className="flex items-center px-2 py-1 border rounded text-caption-color">
                  <Icon type="search" className="fill-current mr-2" />
                  <input
                    placeholder="Search for station or users..."
                    className="focus:outline-none w-56 text-text-color"
                  />
                </div>
                <CreateSection
                  trigger={
                    <Button icon="plus" type="primary">
                      Create Section
                    </Button>
                  }
                  onCreate={handleSectionCreate}
                />
              </div>
              <div className="flex-1 overflow-auto">{content}</div>
            </div>
          </div>
        </div>
      </AppShell>
      <Drawer
        visible={!!editSection}
        onClose={handleEditDrawerClose}
        width={640}
        destroyOnClose
        maskClosable={false}
        title={editSectionData ? `Edit ${editSectionData.name}` : undefined}
        bodyStyle={{ height: 'calc(100% - 55px)' }}
      >
        {editSectionData ? (
          <EditSectionForm
            section={editSectionData}
            onEdit={handleSectionEdit}
          />
        ) : null}
      </Drawer>
    </>
  );
};

export default Registration;
