import React, { useState } from 'react';
import styled, { withTheme } from 'styled-components';
import { Typography, DatePicker } from 'antd';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  CartesianGrid,
  Tooltip,
} from 'recharts';
import moment from 'moment';

import { orderBy } from 'lodash-es';
import { Station } from '../../../types';
import { Theme } from '../../../styles/theme';
import usePoll from '../../../hooks/usePoll';
import Loader from '../../../components/Loader';

interface PurposeStats {
  trend: Array<{
    station: Station;
    stats: { 1: number; 2: number; 3: number; 4: number };
  }>;
  legend: any;
}

interface Props {
  theme: Theme;
}

const RequestPurposeChart: React.FC<Props> = ({ theme }) => {
  const [range, setRange] = useState<[moment.Moment, moment.Moment]>([
    moment().subtract(1, 'day'),
    moment(),
  ]);

  const { loading, data } = usePoll<PurposeStats>(
    `/otp/analysis/purpose?startDate=${range[0].unix() *
      1000}&endDate=${range[1].unix() * 1000}`,
  );

  return (
    <>
      <Typography.Paragraph strong>Request Purpose</Typography.Paragraph>
      <SelectionContainer>
        <StyledRangePicker
          value={range}
          onChange={dates => {
            if (dates.length > 0) {
              setRange([dates[0] as moment.Moment, dates[1] as moment.Moment]);
            } else {
              setRange([moment().subtract(1, 'day'), moment()]);
            }
          }}
        />
      </SelectionContainer>
      <Container>
        {(() => {
          if (loading) {
            return <Loader text="Loading data..." />;
          }

          if (data) {
            const barChartData = orderBy(
              data.trend.map(trendData => ({
                stationName: trendData.station.code,
                ...trendData.stats,
              })),
              item => item.stationName,
            );
            return (
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={barChartData}
                  barSize={8}
                  layout="vertical"
                  margin={{
                    left: -8,
                    right: 8,
                  }}
                >
                  <Tooltip
                    contentStyle={{
                      fontSize: 12,
                    }}
                    labelStyle={{
                      fontWeight: 'bold',
                    }}
                  />
                  <Bar
                    dataKey="1"
                    stackId="request"
                    fill={theme.errorColor}
                    name={data.legend[1]}
                  />
                  <Bar
                    dataKey="2"
                    stackId="request"
                    fill={theme.successColor}
                    name={data.legend[2]}
                  />
                  <Bar
                    dataKey="3"
                    stackId="request"
                    fill={theme.warningColor}
                    name={data.legend[3]}
                  />
                  <CartesianGrid horizontal={false} strokeOpacity={0.5} />
                  <Bar
                    dataKey="4"
                    stackId="request"
                    fill={theme.primaryColor}
                    name={data.legend[4]}
                  />
                  <XAxis type="number" allowDecimals={false} />
                  <YAxis
                    type="category"
                    dataKey="stationName"
                    axisLine={false}
                    tick={{ fontSize: 10 }}
                  />
                  <Legend wrapperStyle={{ fontSize: 12 }} />
                </BarChart>
              </ResponsiveContainer>
            );
          }

          return null;
        })()}
      </Container>
    </>
  );
};

export default withTheme(RequestPurposeChart);

const Container = styled.div`
  padding: 8px;
  height: 560px;
  position: relative;
  background-color: ${({ theme }) => theme.lightBackgroundColor};

  & .recharts-text.recharts-cartesian-axis-tick-value {
    font-size: 12px;
  }

  &&& {
    & .recharts-legend-item {
      display: inline-flex !important;
      align-items: center;
    }
  }
`;

const StyledRangePicker = styled(DatePicker.RangePicker)`
  &&& {
    display: inline-block;
  }
`;

const SelectionContainer = styled.div`
  margin-bottom: 8px;

  & > * + * {
    margin-left: 8px;
  }
`;
