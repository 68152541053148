import React from 'react';
import { Spin, Icon } from 'antd';
import styled from 'styled-components';

interface Props {
  text?: string;
}

const Loader: React.FC<Props> = ({ text }) => {
  const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

  return (
    <Container>
      <Spin indicator={antIcon} tip={text} />
    </Container>
  );
};

export default Loader;

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
`;
