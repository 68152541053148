import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import theme from './styles/theme';
import Route from './components/Route';
import Auth from './components/Auth';

import Login from './scenes/Login';
import OTPTable from './scenes/OTPTable';
import Dashboard from './scenes/Dashboard';
import Registration from './scenes/Registration';

const App: React.FC = () => {
  return (
    <div className="font-sans">
      <ThemeProvider theme={theme}>
        <Auth>
          <Switch>
            <Route path="/login" component={Login} />
            <Route
              path="/"
              exact
              protectedRoute
              render={() => <Redirect to={{ pathname: '/dashboard' }} />}
            />
            <Route path="/dashboard" component={OTPTable} protectedRoute />
            <Route path="/analytics" component={Dashboard} protectedRoute />
            <Route
              path="/registration"
              component={Registration}
              protectedRoute
            />
          </Switch>
        </Auth>
      </ThemeProvider>
    </div>
  );
};

export default App;
