/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

const AuthContext = React.createContext({
  authToken: null,
  user: null,
  signInWithEmail: (email: string, password: string) => {},
  signOut: () => {},
  initiateResetPassword: (email: string) => {},
  resetPassword: (email: string, newPassword: string) => {},
});

export default AuthContext;
