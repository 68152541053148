import { useContext, useMemo } from 'react';
import Axios from 'axios';

import AuthContext from '../contexts/AuthContext';

const useApi = () => {
  const { authToken } = useContext(AuthContext);

  const api = useMemo(() => {
    const apiObject = Axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
      headers: {
        Authorization: authToken,
      },
    });

    // TODO: udpate AuthToken if there is any authToken field present
    // in the response hanlder
    apiObject.interceptors.response.use(response => response);

    return apiObject;
  }, [authToken]);

  return api;
};

export default useApi;
